/**
 * 
 * Edit Video
 * 
 * */
import React, { useState, useEffect } from 'react'; 
import ConstantsList from '../constants';
import SideBar from './SideBar';
import Search from './Search';
import {useDropzone} from 'react-dropzone';
import Select from 'react-select';
import Flow from "@flowjs/flow.js";

function EditVideo() {


   const [file, setFile] = useState({}); 
   const [fileThumbnail, setFileThumbnail] = useState({}); 
   const [uploadProgress, setUploadProgress] = useState(0);
   const [uploadProgressComplete, setUploadProgressCompete] = useState(0);

   const { getRootProps:getRootfileProps, getInputProps:getInputfileProps  } = useDropzone({
       accept: 'image/*',
       onDrop: (acceptedFile) => {
           setFile(
               Object.assign(acceptedFile[0], {
                   preview: URL.createObjectURL(acceptedFile[0]),
               }),
           );

          const flow = new Flow({
            target: ConstantsList.API_ENDPOINT+ '/flowphp/upload.php',
          });

          const file = acceptedFile[0];

          flow.on('complete', () => {
            setUploadProgressCompete('vcdn-upload-completed');
          });

          flow.on('fileProgress', (file) => {
            const progress = file.progress();
            console.log(`File upload progress (${file.name}): ${progress}%`);
            setUploadProgress(progress*100);
          });
          flow.addFile(file);

          flow.upload();



       },
   });

   const { getRootProps:getRootThumbnailProps, getInputProps:getInputThumbnailProps } = useDropzone({
       accept: 'image/*',
       onDrop: (acceptedFile) => {
           setFileThumbnail(
               Object.assign(acceptedFile[0], {
                   preview: URL.createObjectURL(acceptedFile[0]),
               }),
           );
       },
   });


  const submitHandler = (event) => {
    event.preventDefault();
    document.getElementById("vcdn-login-panel-form").style.opacity = "0.3";
  //  uploadVideoRequest(); 
  }



  const [playlistData, setPlaylistData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let headers = new Headers();
        headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    
        const response = await fetch(ConstantsList.API_ENDPOINT + '/ajax/getAlbums', {
          method: 'GET',
          headers: headers
        });
        const data = await response.json();
        setPlaylistData(data.albums);
      } catch (error) {
        console.log(error);
      }
    };
    
    fetchData();
  }, []);

  const options = playlistData.map((album) => ({
    value: album.id,
    label: album.title
  }));

  return (
<div className="VideoCDN-UploadPage">
        <div className="vcdn-dashboard-main">
            <div className="vcdn-dashboard-inner">
              <div className="vcdn-dashboard-sidebar">
                  <SideBar />
              </div>
              <div className="vcdn-dashboard-right-panel">
                 <div className="vcdn-dashboard-right-panel-inner">
                    <Search />

                    <form className="vcdn-dashboard-upload" onSubmit={submitHandler}>
                      <div className="vcdn-dashboard-upload-inner">
                        <div className="vcdn-dashboard-upload-title">
                          Edit Video
                        </div>
                        <div className="vcdn-dashboard-upload-main">
                          <div className="vcdn-dashboard-form-group">
                            <label htmlFor="vcdn-video-title">Video Title</label>
                            <div className="vcdn-video-form-input">
                              <input type="text" name="vcdn-video-title" id="vcdn-video-title" placeholder="Here it goes the title of the video" />
                            </div>
                          </div>

                          <div className="vcdn-dashboard-form-group">
                            <div className="vcdn-video-form-upload-videos">

                                <div className="vcdn-video-upload-container">
                                  <div {...getRootfileProps({className: 'dropzonevideo'})}>
                                    <input {...getInputfileProps()} />
                                    <div className="vcdn-video-form-dropicon">
                                     <svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24" color="rgb(255, 255, 255)"><path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z"></path></svg>
                                    </div>
                                    <button type="button" className="vcdn-video-form-btn" >
                                      Browse
                                    </button>
                                    <div className="vcdn-dashboard-upload-txt">
                                    Please note that <strong>only .mp4</strong> files can be used.
                                    <span>Uploading and processing the video may take some time with large files.</span>
                                    </div>
                                  </div>
                                  {file.preview ? (
                                  <div className="vcdn-video-preview-panel">
                                      <div className="vcdn-video-preview-panel-block">
                                        <video width="750" height="500" controls >
                                              <source src={file.preview} type="video/mp4"/>
                                        </video>
                                        

                                        <div className="vcdn-video-loading-bar">
                                          <div className="vcdn-video-loading-inner">
                                            <div className="vcdn-video-loading-progress">
                                                <div className={'vcdn-video-loading-progress-bar ' + uploadProgressComplete} style={{ width: `${uploadProgress}%` }}></div>
                                            </div>
                                          </div>
                                        </div>


                                      </div>
                                  </div>
                                  ): null}

                                </div>
                            </div>
                          </div>


                          <div className="vcdn-dashboard-upload-main vcdn-video-preview-contain">
                            <div className="vcdn-dashboard-form-group">
                              <label htmlFor="vcdn-video-preview">Preview picture</label>
                              <div className="vcdn-video-form-input">
                              

                                <div className="vcdn-video-upload-container vcdn-video-camera-contain">
                                  <div {...getRootThumbnailProps({className: 'dropzonethumb'})}>
                                    <input {...getInputThumbnailProps()} />
                                    <div className="vcdn-video-form-dropicon vcdn-video-form-camera">
                                     <svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24" ><circle cx="12" cy="12" r="3.2"></circle><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"></path></svg>
                                    </div>
                                    <button type="button" className="vcdn-video-form-btn-link" >
                                      Browse
                                    </button>
                                    <div className="vcdn-dashboard-upload-txt">
                                       File dimention: 1920 x 1080
                                    </div>
                                  </div>
                                  
                                  {fileThumbnail.preview ? (
                                  <div className="vcdn-image-preview-panel">
                                      <div className="vcdn-image-preview-panel-block">
                                          
                                          <div className="vcdn-image-preview-thumbnail" style={{ backgroundImage: `url('`+fileThumbnail.preview+`')` }}>
                                          </div>

                                      </div>
                                  </div>
                                  ): null}

                                </div>

                              </div>
                            </div> 
                          </div>                         

                          <div className="vcdn-dashboard-upload-main vcdn-dashboard-upload-playlist">
                            <div className="vcdn-dashboard-form-group">
                              <label htmlFor="vcdn-video-playlist">Select playlist</label>
                              <div className="vcdn-video-form-select">
                                <Select isMulti className="vcdn-playlist-dropdown" classNamePrefix="vcdn-playlist" options={options} />
                              </div>
                            </div>   
                          </div>


                          <div className="vcdn-dashboard-form-group">
                            <div className="vcdn-video-form-button">
                              <button type="submit">Upload</button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </form>

                  </div>
              </div>
            </div>
        </div>
    </div>
  );
}

export default EditVideo;
