/**
 * 
 * Sidebar Template
 * 
 * */
import React, { useState, useEffect, useRef } from 'react';
import ConstantsList from '../constants';

function SideBar() {
  const checkbox = useRef();
  const handleSwitchChange = () => {
    if (checkbox.current.checked) {
       document.body.classList['add']('light-mode');
    } else {
      document.body.classList['remove']('light-mode');
    }
  }
  const [playlists, setPlaylists] = useState([]);
  useEffect(() => {
    let headers = new Headers();
    headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    headers.set('Authorization', 'Bearer ' + localStorage.getItem('token') );
    fetch(ConstantsList.API_ENDPOINT + '/ajax/getAlbums', {
      method: 'GET',
      headers: headers
      })
      .then(response => response.json())
      .then(data => setPlaylists(data.albums))
      .catch(error => console.error(error));
  }, []);
return (  
<div className="vcdn-dashboard-sidebar-inner">
    <div className="vcdn-dashboard-logo">
      <div className="vcdn-dashboard-logo-txt"><strong>Digi</strong>member</div>
    </div>
    <div className="vcdn-dashboard-profile-part">
      <div className="vcdn-dashboard-profile-inner">

          <div className="vcdn-dashboard-profile-actions">
            <div className="vcdn-dashboard-profile-actions-inner">
              <div className="vcdn-dashboard-profile-switch">
              <label className="vcdn-dashboard-switch">
                  <input type="checkbox" id="light_mode_switch" onChange={handleSwitchChange} ref={checkbox} /> 
                  <span className="vcdn-dashboard-slider"></span>
              </label>

              </div>
              <div className="vcdn-dashboard-profile-other-actions">
                  <div className="vcdn-dashboard-profile-setting">
                    <a href="/users">
                        <svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24" ><path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z"></path></svg>
                   </a>
                  </div>
                  <div className="vcdn-dashboard-profile-logout">
                    <a href="">
                      <span className="material-symbols-outlined">logout</span>
                    </a>
                  </div>
              </div>
            </div>
          </div>

          <div className="vcdn-dashboard-profile-image">
              <div className="vcdn-dashboard-profile-image-holder" style={{backgroundImage:`url('/images/profile-pic-temp.jpeg')`}}>
              </div>
          </div>
          <div className="vcdn-dashboard-profile-subtitle">
            UxDesign
          </div>
          <div className="vcdn-dashboard-profile-email">
            info@carlociccarelli.it
          </div>
      </div>
    </div>

    <div className="vcdn-dashboard-upload-button">
      <a href="/upload"><svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24" color="rgb(255, 255, 255)" ><path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z"></path></svg><span className="vcdn-dashboard-upload-button-txt">Upload</span></a>
    </div>

    <div className="vcdn-dashboard-all-videos-button">
      <a href="/dashboard"><svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24" ><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 12.5v-9l6 4.5-6 4.5z"></path></svg><span className="vcdn-dashboard-videos-button-txt">All videos</span><span className="material-symbols-rounded">chevron_right</span></a>
    </div>

    <div className="vcdn-dashboard-playlists">
      <div className="vcdn-dashboard-playlists-inner">
          <h3 className="vcdn-dashboard-playlists-title">Playlists
            <div className="vcdn-dashboard-add-list">
              <a href="/add-album"><span className="material-symbols-outlined">add</span></a>
            </div>
          </h3>
          <ul>
            {playlists.map(playlist => (
                <li key={playlist.id}><input type="checkbox" id={"playlist" + playlist.id} /> <label htmlFor={"playlist" + playlist.id}>{playlist.title}  <a href={"/edit-album/" + playlist.id}><svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24" ><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg></a></label></li>
             ))}
          </ul>
      </div>
    </div>

</div>
);
}
export default SideBar;