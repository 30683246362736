import ReactDOM from 'react-dom';
import React from 'react';
import ConstantsList from './constants';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Login,
  Dashboard,
  Upload,
  Settings,
  AddAlbum,
  EditVideo,
  EditAlbum,
  SearchPage,
  Users,
  Security,
  AddNewUser
} from "./components";

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/upload" element={<Upload />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/add-album" element={<AddAlbum />} />
      <Route path="/users" element={<Users />} />
      <Route path="/edit-video/:id" element={<EditVideo />} />
      <Route path="/edit-album/:id" element={<EditAlbum />} />
      <Route path="/search/:searchterm" element={<SearchPage />} />
      <Route path="/new-user" element={<AddNewUser />} />
      <Route path="/security" element={<Security />} />  
    </Routes>
  </Router>,
  document.getElementById("root")
);