import React, { useState, useEffect } from 'react';
import ConstantsList from '../constants';
import SideBar from './SideBar';
import Search from './Search';
import LoadingVideos from './LoadingVideos';
import Slider from 'react-slick';
import VideoCard from './VideoCard';

const fetchVideos = async (albumId) => {
  try {

    let headers = new Headers();
    headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));

    const response = await fetch(ConstantsList.API_ENDPOINT + `/ajax/getVideos?album_filter=${albumId}`, {
          method: 'GET',
          headers: headers,
        });
    const data = await response.json();
     return data;

  } catch (error) {
    console.log(error);
    return [];
  }
};

function VideosComponent({ albumId }) {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedVideos = await fetchVideos(albumId);
      setVideos(fetchedVideos);
    };

    fetchData();
  }, [albumId]);
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  return (
    (videos.length > 0) ? 
    <Slider {...settings}>
      {videos.map((video) => (
          <VideoCard video={video} key={video.id} />
      ))}
    </Slider>
    : null
  );
}

function Dashboard() {

  const [playlistData, setPlaylistData] = useState([]);
  const [checkVideoExists, setCheckVideoExists] = useState([]);
  const [videoFlag, setVideoFlag] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let headers = new Headers();
        headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));

        const response = await fetch(ConstantsList.API_ENDPOINT + '/ajax/getAlbums', {
          method: 'GET',
          headers: headers,
        });
        const data = await response.json();
        setPlaylistData(data.albums);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const checkVideos = async () => {
      for (const playlist of playlistData) {
        const videos = await fetchVideos(playlist.id);
        if (videos.length > 0) {
          setVideoFlag(true);
          break;
        }
      }
    };

    checkVideos();
  }, [playlistData]);

  return (
    <div className="VideoCDN-DashboardPage">
      <div className="vcdn-dashboard-main">
        <div className="vcdn-dashboard-inner">
          <div className="vcdn-dashboard-sidebar">
            <SideBar />
          </div>
          <div className="vcdn-dashboard-right-panel">
            <div className="vcdn-dashboard-right-panel-inner">
              <Search />

              <div className="vcdn-dashboard-videos">
                {videoFlag ? (
                  playlistData.map((playlist) => {
                    const videosExist = fetchVideos(playlist.id);
                    videosExist.then(function(response) {
                      if(response.length > 0){
                        setCheckVideoExists(true);
                      }
                    });
                    console.log(checkVideoExists);
                    return (
                      checkVideoExists && (
                        <div className="vcdn-dashboard-videos-list" key={playlist.id}>
                          <div className="vcdn-dashboard-videos-title">{playlist.title}</div>
                          <div className="vcdn-dashboard-videos-lists">
                            <div className="vcdn-dashboard-videos-lists-inner">
                              <VideosComponent albumId={playlist.id} />
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <div className="vcdn-loading-videos">
                    <LoadingVideos />
                  </div>
                )}
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;