/**
 * 
 * Login
 * 
 * */
import ConstantsList from '../constants';
import { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";

function Login() {      
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
    const ref = useRef(null);
    const usernameHandler = (event) => {
      setUsername(event.target.value)
    }

    const passwordHandler = (event) => {
      setPassword(event.target.value)
    }

    const submitHandler = (event) => {
      event.preventDefault();
      document.getElementById("vcdn-login-panel-form").style.opacity = "0.3";
      loginRequest(); 
    }

    async function loginRequest() {
      let headers = new Headers();
      try {
        headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));

        await fetch(ConstantsList.API_ENDPOINT + '/ajax/login', {
          method: 'GET',
          headers: headers
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
              document.getElementById("vcdn-login-panel-form").style.opacity = "1";
            }
            throw new Error('error')
          })
          .then((data) => {
            if (data.access_token) {
              localStorage.setItem('token', data.access_token);
              navigate("/dashboard");
            }
            else{
             document.getElementById("vcdn-login-panel-form").style.opacity = "1";
             document.getElementById("vcdn-message-box").innerHTML = '<div class="vcdn-error-box">'+ConstantsList.LOGIN_ERROR+'</div>';
            }
          })
      } catch (error) {
        console.log(error);
          document.getElementById("vcdn-login-panel-form").style.opacity = "1";
          document.getElementById("vcdn-message-box").innerHTML = '<div class="vcdn-error-box">'+ConstantsList.INVALID_LOGIN_RESPONSE+'</div>';
      }
  }


  return (
    <div className="VideoCDN-LoginPage">
      <div className="vcdn-login-main">
        <div className="vcdn-login-inner">
          <div className="vcdn-long-logo-container">
            <img src="./images/logo-long.png" className="vcdn-login-image" alt="logo" />
          </div>
          <div className="vcdn-login-panel">
            <div className="vcdn-login-panel-inner">
              <div className="vcdn-login-panel-col-1">
                <div className="vcdn-login-panel-col-1-inner">
                    <div className="vcdn-login-panel-hello-txt">
                      Hello!
                    </div>
                    <div className="vcdn-login-panel-form-container">
                      <form action="" method="POST" onSubmit={submitHandler} id="vcdn-login-panel-form" className="vcdn-login-panel-form">
                        <div className="vcdn-login-panel-fields">
                          <div className="vcdn-login-panel-field vcdn-field-email">
                            <label htmlFor="vcdn-login-panel-email">E-mail</label>
                            <div className="vcdn-login-panel-input-inner">
                             <input type="text" value={username} onChange={usernameHandler} className="vcdn-login-panel-input" id="vcdn-login-panel-email" />
                            </div>
                          </div>
                          <div className="vcdn-login-panel-field vcdn-field-pass">
                            <label htmlFor="vcdn-login-panel-password">Password</label>
                            <div className="vcdn-login-panel-input-inner">
                             <input type="password" value={password} onChange={passwordHandler} className="vcdn-login-panel-input" id="vcdn-login-panel-password" />
                            </div>
                          </div>

                          <div className="vcdn-login-panel-field vcdn-field-submit">
                            <div className="vcdn-login-panel-submit-inner">
                             <input type="submit" className="vcdn-login-panel-input" id="vcdn-login-panel-submit" value="Login" />
                            </div>
                          </div>

                        </div>
                      </form>
                      <div className="vcdn-message-box" id="vcdn-message-box">
                      </div>
                    </div>
                </div>
              </div>
              <div className="vcdn-login-panel-col-2">
                 <div className="vcdn-login-panel-col-2-inner">
                    <div className="vcdn-login-panel-video-col">
                      <div className="vcdn-login-panel-video-col-inner">
                          <div className="vcdn-login-video-holder">
                              <video autoPlay muted loop>
                                <source src="./videos/loginscreen.mp4" type="video/mp4" />
                              </video>
                          </div>
                          <div className="vcdn-login-panel-video-overlay">
                            <div className="vcdn-login-panel-video-overlay-txt">
                            Self Hosted <br />Video Platform
                            </div>
                          </div>
                      </div>
                    </div>
                 </div>
              </div>
            </div>
          </div>
        </div>

      <div className="main-wrapper">
        <div className="noise-wrapper">
          <div className="noise"></div>
        </div>
      </div>


      </div>
    </div>
  );
}

export default Login;
