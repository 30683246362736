/**
 * 
 * Dashboard
 * 
 * */
import ConstantsList from '../constants';
import SideBar from './SideBar';
import Search from './Search';
import { useNavigate } from "react-router-dom";
function Security() {
  const navigate = useNavigate();
  const submitHandler = (event) => {
    event.preventDefault();
    addAlbum(); 
  }
  async function addAlbum() {
    document.getElementById("vcdn-albmum-panel-form").style.opacity = "0.3";
    let headers = new Headers();

    try {
      headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      headers.set('Authorization', 'Bearer ' + localStorage.getItem('token') );

      const urlencoded = new URLSearchParams({
        "username": document.getElementById("vcdn-username").value,
        "password": document.getElementById("vcdn-password").value,
        "email": document.getElementById("vcdn-email").value,
        "userrole": "100",
      });

      await fetch(ConstantsList.API_ENDPOINT + '/ajax/addUser', {
        method: 'POST',
        headers: headers,
        body: urlencoded
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error')
        })
        .then((data) => {
          document.getElementById("vcdn-albmum-panel-form").style.opacity = "1";
          document.getElementById("vcdn-albumname").value = "";
          navigate("/dashboard");
          document.getElementById("vcdn-message-box").innerHTML = '<div class="vcdn-success-box">'+ConstantsList.ALBUM_SUCCESS_MESSAGE+'</div>';
             
        })
    } catch (error) {

   }
  }
  return (
<div className="VideoCDN-SecurityUserPage">
        <div className="vcdn-dashboard-main">
            <div className="vcdn-dashboard-inner">
              <div className="vcdn-dashboard-sidebar">
                  <SideBar />
              </div>
              <div className="vcdn-dashboard-right-panel">
                 <div className="vcdn-dashboard-right-panel-inner">
                    <Search />

                    <div className="vcdn-dashboard-upload">
                      <div className="vcdn-dashboard-upload-inner">
                        <div className="vcdn-dashboard-upload-main">
                          <div className="vcdn-all-tabs">
                            <div className="vcdn-single-tab">
                              <a href="/users">Users</a>
                              <a href="/security">Security</a>
                              <a href="/users">AWS Settings</a>
                              <a href="/settings">Profile</a>
                            </div>
                          </div>


                          <div className="vcdn-dashboard-notice-box">
                            <div className="vcdn-dashboard-notice-box-head">
                              Security
                            </div>
                            <div className="vcdn-dashboard-notice-box-block">
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit
                            </div>
                          </div>
                          <div className="vcdn-dashboard-upload-title">Credentials</div>
                          <div className="vcdn-message-box" id="vcdn-message-box">
                          </div>
                          <form action="" id="vcdn-albmum-panel-form" method="POST" onSubmit={submitHandler}>
                            <div className="vcdn-dashboard-form-group">
                              <label htmlFor="vcdn-username">User Name</label>
                              <div className="vcdn-video-form-input">
                                <input type="text" name="vcdn-username" id="vcdn-username" placeholder="" />
                              </div>
                            </div>
                         
            

                            <div className="vcdn-dashboard-form-group">
                              <label htmlFor="vcdn-email">Email</label>
                              <div className="vcdn-video-form-input">
                                <input type="email" name="vcdn-email" id="vcdn-email" placeholder="" />
                              </div>
                            </div>

                            <div className="vcdn-dashboard-form-group">
                              <div className="vcdn-video-form-button vcdn-video-form-button-left">
                                <button type="submit">Save Setting</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                  </div>
              </div>
            </div>
        </div>
    </div>
  );
}

export default Security;