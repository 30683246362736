/**
 * 
 * Sidebar Template
 * 
 * */
import React from 'react';
function LoadingVideos() {
return (  
<div className="vcdn-dashboard-videos-list">  

 <div className="vcdn-dashboard-videos-title text-line"></div>
 <div className="vcdn-dashboard-videos-lists">
  <div className="vcdn-dashboard-videos-lists-inner">


      <div className="vcdn-dashboard-videos-list-item">
          <div className="vcdn-dashboard-videos-list-item-thumb animated-background" >
            <div className="vcdn-dashboard-videos-list-item-overlay">

            </div>
          </div>
          <div className="vcdn-dashboard-videos-list-item-meta-wrap">
            <div className="vcdn-dashboard-videos-list-item-meta">
              <div className="vcdn-dashboard-videos-list-item-title text-line">Loading Title..</div>
              <div className="vcdn-dashboard-videos-list-item-info">
                <div className="vcdn-dashboard-videos-list-item-time text-line">04:00</div>
                <div className="vcdn-dashboard-videos-list-item-line"></div>
                <div className="vcdn-dashboard-videos-list-item-size text-line">000MB</div>
              </div>
            </div>

            <div className="vcdn-dashboard-videos-list-item-actions">
              <div className="vcdn-dashboard-videos-list-item-button vcdn-dashboard-edit text-line">
               
              </div>
              <div className="vcdn-dashboard-videos-list-item-button vcdn-dashboard-embed text-line">
               
              </div>
            </div>
          </div>
        </div>

      <div className="vcdn-dashboard-videos-list-item">
          <div className="vcdn-dashboard-videos-list-item-thumb animated-background" >
            <div className="vcdn-dashboard-videos-list-item-overlay">

            </div>
          </div>
          <div className="vcdn-dashboard-videos-list-item-meta-wrap">
            <div className="vcdn-dashboard-videos-list-item-meta">
              <div className="vcdn-dashboard-videos-list-item-title text-line">Loading Title..</div>
              <div className="vcdn-dashboard-videos-list-item-info">
                <div className="vcdn-dashboard-videos-list-item-time text-line">04:00</div>
                <div className="vcdn-dashboard-videos-list-item-line"></div>
                <div className="vcdn-dashboard-videos-list-item-size text-line">000MB</div>
              </div>
            </div>

            <div className="vcdn-dashboard-videos-list-item-actions">
              <div className="vcdn-dashboard-videos-list-item-button vcdn-dashboard-edit text-line">
               
              </div>
              <div className="vcdn-dashboard-videos-list-item-button vcdn-dashboard-embed text-line">
               
              </div>
            </div>
          </div>
        </div>


      <div className="vcdn-dashboard-videos-list-item">
          <div className="vcdn-dashboard-videos-list-item-thumb animated-background" >
            <div className="vcdn-dashboard-videos-list-item-overlay">

            </div>
          </div>
          <div className="vcdn-dashboard-videos-list-item-meta-wrap">
            <div className="vcdn-dashboard-videos-list-item-meta">
              <div className="vcdn-dashboard-videos-list-item-title text-line">Loading Title..</div>
              <div className="vcdn-dashboard-videos-list-item-info">
                <div className="vcdn-dashboard-videos-list-item-time text-line">04:00</div>
                <div className="vcdn-dashboard-videos-list-item-line"></div>
                <div className="vcdn-dashboard-videos-list-item-size text-line">000MB</div>
              </div>
            </div>

            <div className="vcdn-dashboard-videos-list-item-actions">
              <div className="vcdn-dashboard-videos-list-item-button vcdn-dashboard-edit text-line">
               
              </div>
              <div className="vcdn-dashboard-videos-list-item-button vcdn-dashboard-embed text-line">
               
              </div>
            </div>
          </div>
        </div>


      <div className="vcdn-dashboard-videos-list-item">
          <div className="vcdn-dashboard-videos-list-item-thumb animated-background" >
            <div className="vcdn-dashboard-videos-list-item-overlay">

            </div>
          </div>
          <div className="vcdn-dashboard-videos-list-item-meta-wrap">
            <div className="vcdn-dashboard-videos-list-item-meta">
              <div className="vcdn-dashboard-videos-list-item-title text-line">Loading Title..</div>
              <div className="vcdn-dashboard-videos-list-item-info">
                <div className="vcdn-dashboard-videos-list-item-time text-line">04:00</div>
                <div className="vcdn-dashboard-videos-list-item-line"></div>
                <div className="vcdn-dashboard-videos-list-item-size text-line">000MB</div>
              </div>
            </div>

            <div className="vcdn-dashboard-videos-list-item-actions">
              <div className="vcdn-dashboard-videos-list-item-button vcdn-dashboard-edit text-line">
               
              </div>
              <div className="vcdn-dashboard-videos-list-item-button vcdn-dashboard-embed text-line">
               
              </div>
            </div>
          </div>
        </div>





    </div>
  </div>
</div>
);
}
export default LoadingVideos;