/**
 * 
 * Search Bar
 * 
 * */
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Search() {
  const { searchterm } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const submitHandler = (event) => {
    event.preventDefault();
    console.log(searchTerm);
    if (searchTerm.trim() !== "") {
    	navigate("/search/"+ searchTerm);
    }
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="vcdn-dashboard-right-top-block">
      <div className="vcdn-dashboard-right-search-center">
        <div className="vcdn-dashboard-right-search-main">
          <form onSubmit={submitHandler}>
            <button className="vcdn-dashboard-search-btn">
              <svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24">
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
              </svg>
            </button>
            <input type="text" className="vcdn-dashboard-seach-field" defaultValue={searchterm} onChange={handleChange} />
          </form>
        </div>
      </div>
    </div>
  );
}

export default Search;