/**
 * 
 * Video Card
 * 
 * */
import ConstantsList from '../constants';

function BytesToMB(bytes) {
  const convertBytesToMB = (bytes) => {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2); // Limiting the result to 2 decimal places
  };

  const megabytes = convertBytesToMB(bytes);

  return <span>{megabytes} MB</span>;
}


function VideoCard(props) {
  console.log(props.video);
  let imageType = props.video.file_preview_mime;
  console.log(imageType);
  if(imageType){
    imageType = imageType.split("/");
  }
  return (
   <div className="vcdn-dashboard-videos-list-item" key={props.video.id}>
      <div className="vcdn-dashboard-videos-list-item-thumb" style={{ backgroundImage: `url('`+ ConstantsList.API_ENDPOINT + "/shared/uploads/images/" + props.video.uniq + "." + imageType[1] +`')` }}>
        <div className="vcdn-dashboard-videos-list-item-overlay">
          <div className="vcdn-dashboard-videos-list-item-overlay-inner">
            <div className="vcdn-dashboard-videos-list-item-overlay-btn">
              <a href="">
                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24">
                  <path d="M8 5v14l11-7z"></path>
                </svg>{' '}
                <span>Preview</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="vcdn-dashboard-videos-list-item-meta-wrap">
        <div className="vcdn-dashboard-videos-list-item-meta">
          <div className="vcdn-dashboard-videos-list-item-title">{props.video.title}</div>
          <div className="vcdn-dashboard-videos-list-item-info">
            <div className="vcdn-dashboard-videos-list-item-time">2:30</div>
            <div className="vcdn-dashboard-videos-list-item-line"></div>
            <div className="vcdn-dashboard-videos-list-item-size">{BytesToMB(props.video.file_size)}</div>
          </div>
        </div>

        <div className="vcdn-dashboard-videos-list-item-actions">
          <div className="vcdn-dashboard-videos-list-item-button vcdn-dashboard-edit">
            <a href={"/edit-video/" + props.video.id}>
              <svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24">
                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
              </svg>
              <span>Edit</span>
            </a>
          </div>
          <div className="vcdn-dashboard-videos-list-item-button vcdn-dashboard-embed">
            <a href="">
              <svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24">
                <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"></path>
              </svg>
              <span>Embed</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoCard;
