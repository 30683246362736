import React, { useState, useEffect } from 'react';
import ConstantsList from '../constants';
import SideBar from './SideBar';
import Search from './Search';

function Users() {
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const headers = new Headers();
        headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));

        const response = await fetch(ConstantsList.API_ENDPOINT + '/ajax/getUsers', {
          method: 'GET',
          headers: headers
        });
        const data = await response.json();
        setAllUsers(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="VideoCDN-UsersPage">
      <div className="vcdn-dashboard-main">
        <div className="vcdn-dashboard-inner">
          <div className="vcdn-dashboard-sidebar">
            <SideBar />
          </div>
          <div className="vcdn-dashboard-right-panel">
            <div className="vcdn-dashboard-right-panel-inner">
              <Search />

              <div className="vcdn-dashboard-upload">
                <div className="vcdn-dashboard-upload-inner">
                  <div className="vcdn-dashboard-upload-main">
                    <div className="vcdn-all-tabs">
                      <div className="vcdn-single-tab">
                        <a href="/users">Users</a>
                        <a href="/security">Security</a>
                        <a href="/users">AWS Settings</a>
                        <a href="/settings">Profile</a>
                      </div>
                    </div>
                    <div className="vcdn-all-users-main">
                      {allUsers.map((user) => (
                        <div key={user.id} className="vcdn-user-info">
                          <a href={"/edit-user/"+ user.id}><div className="vcdn-username-list"><span>{user.username}</span> <span class="material-symbols-rounded">chevron_right</span></div></a>
                        </div>
                      ))}
                    </div>
                    <div className="vcdn-add-new-user">
                      <a href="/new-user">Add New User</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;