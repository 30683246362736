import React, { useState, useEffect } from 'react';
import ConstantsList from '../constants';
import SideBar from './SideBar';
import Search from './Search';
import LoadingVideos from './LoadingVideos';
import Slider from 'react-slick';
import { useNavigate, useParams } from "react-router-dom";
import VideoCard from "./VideoCard";

function SearchPage() {
  const { searchterm } = useParams();
  const [videos, setVideos] = useState([]);

  const fetchVideos = async () => {
    try {
      let headers = new Headers();
      headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));

      const response = await fetch(ConstantsList.API_ENDPOINT + `/ajax/getVideos?search_filter=${searchterm}`, {
        method: 'GET',
        headers: headers,
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchedVideos = await fetchVideos();
      setVideos(fetchedVideos);
    };

    fetchData();
  }, []);

  function VideosComponent() {
    return (
      <div className="vcdn-search-results">
        {videos.map((video) => (
          <VideoCard video={video} />
        ))}
      </div>
    );
  }

  return (
    <div className="VideoCDN-DashboardPage">
      <div className="vcdn-dashboard-main">
        <div className="vcdn-dashboard-inner">
          <div className="vcdn-dashboard-sidebar">
            <SideBar />
          </div>
          <div className="vcdn-dashboard-right-panel">
            <div className="vcdn-dashboard-right-panel-inner">
              <Search />

              <div className="vcdn-dashboard-videos">
                <div className="vcdn-dashboard-videos-lists">
                  <div className="vcdn-dashboard-videos-lists-inner">
                    <VideosComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchPage;
