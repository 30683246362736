/**
 * 
 * Dashboard
 * 
 * */
import ConstantsList from '../constants';
import SideBar from './SideBar';
import Search from './Search';
import Select from 'react-select';

function Settings_Decide_Language(value){
  if(value[0]){
    value = value[0].value;
    if(value == 'english'){
      return 'vcdn-english-select';
    }
    if(value == 'german'){
      return 'vcdn-german-select';
    }
  }
}

function Settings() {

  const options = [
    { value: 'english', label: 'English' },
    { value: 'german', label: 'German' }
  ];

  return (
<div className="VideoCDN-SettingsPage">
        <div className="vcdn-dashboard-main">
            <div className="vcdn-dashboard-inner">
              <div className="vcdn-dashboard-sidebar">
                  <SideBar />
              </div>
              <div className="vcdn-dashboard-right-panel">
                 <div className="vcdn-dashboard-right-panel-inner">
                    <Search />

                    <div className="vcdn-dashboard-upload">
                      <div className="vcdn-dashboard-upload-inner">
                        <div className="vcdn-dashboard-upload-main">

                          <div className="vcdn-all-tabs">
                            <div className="vcdn-single-tab">
                              <a href="/users">Users</a>
                              <a href="/security">Security</a>
                              <a href="/users">AWS Settings</a>
                              <a href="/settings">Profile</a>
                            </div>
                          </div>
                        
                          <div className="vcdn-dashboard-form-group">
                            <div className="vcdn-dashboard-language-selector">
                                <label>Select system language</label>
                                <div className="vcdn-dashboard-language-dropdown">
                                    <Select className="vcdn-language-dropdown" isSearchable={false} classNames={{control: (state) =>  Settings_Decide_Language(state.getValue()) }} classNamePrefix="vcdn-language-selctor" options={options} />
                                </div>
                            </div>
                          </div>

                          <div className="vcdn-dashboard-form-group-title">Credentials</div>

                          <div className="vcdn-dashboard-form-group">
                            <label htmlFor="vcdn-username">Username</label>
                            <div className="vcdn-video-form-input">
                              <input type="text" name="vcdn-username" id="vcdn-username" placeholder="" />
                            </div>
                          </div>

                          <div className="vcdn-dashboard-form-group">
                            <label htmlFor="vcdn-email">E-mail</label>
                            <div className="vcdn-video-form-input">
                              <input type="text" name="vcdn-email" id="vcdn-email" placeholder="" />
                            </div>
                          </div>  


                          <div className="vcdn-dashboard-form-group-title">Change password</div>


                          <div className="vcdn-dashboard-form-group">
                            <label htmlFor="vcdn-oldpass">Old Password</label>
                            <div className="vcdn-video-form-input">
                              <input type="password" name="vcdn-oldpass" id="vcdn-oldpass" placeholder="" />
                            </div>
                          </div>

                          <div className="vcdn-dashboard-form-group">
                            <label htmlFor="vcdn-oldpass">New password</label>
                            <div className="vcdn-video-form-input">
                              <input type="password" name="vcdn-oldpass" id="vcdn-oldpass" placeholder="" />
                            </div>
                          </div>
                         

                          <div className="vcdn-dashboard-form-group">
                            <label htmlFor="vcdn-oldpass">Repeat new password</label>
                            <div className="vcdn-video-form-input">
                              <input type="password" name="vcdn-oldpass" id="vcdn-oldpass" placeholder="" />
                            </div>
                          </div>


                          <div className="vcdn-dashboard-form-group">
                            <div className="vcdn-video-form-button vcdn-video-form-button-left">
                              <button>Save</button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
              </div>
            </div>
        </div>
    </div>
  );
}

export default Settings;
