/**
 * 
 * Edit Album
 * 
 */

import { useState, useEffect } from "react";
import ConstantsList from '../constants';
import SideBar from './SideBar';
import Search from './Search';
import { useNavigate, useParams } from "react-router-dom";

function EditAlbum() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [albumTitle, setAlbumTitle] = useState("");

  useEffect(() => {
    fetchAlbums();
  }, []);

  const fetchAlbums = async () => {
    try {
      let headers = new Headers();
      headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const response = await fetch(ConstantsList.API_ENDPOINT + '/ajax/getAlbums', {
        method: 'GET',
        headers: headers
      });
      const data = await response.json();

      if (data.error === false && data.albums.length > 0) {
        const album = data.albums.find(album => album.id.toString() === id);
        if (album) {
          setAlbumTitle(album.title);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    updateAlbum();
  }

  async function updateAlbum() {
    document.getElementById("vcdn-albmum-panel-form").style.opacity = "0.3";
    let headers = new Headers();

    try {
      headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));

      const urlencoded = new URLSearchParams({
        "id": id,
        "title": document.getElementById("vcdn-albumname").value,
      });

      await fetch(ConstantsList.API_ENDPOINT + '/ajax/editAlbum', {
        method: 'POST',
        headers: headers,
        body: urlencoded
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('error')
        })
        .then((data) => {
          document.getElementById("vcdn-albmum-panel-form").style.opacity = "1";
          document.getElementById("vcdn-albumname").value = "";
          navigate("/dashboard");
          document.getElementById("vcdn-message-box").innerHTML = '<div class="vcdn-success-box">' + ConstantsList.ALBUM_SUCCESS_MESSAGE + '</div>';
        });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="VideoCDN-SettingsPage">
      <div className="vcdn-dashboard-main">
        <div className="vcdn-dashboard-inner">
          <div className="vcdn-dashboard-sidebar">
            <SideBar />
          </div>
          <div className="vcdn-dashboard-right-panel">
            <div className="vcdn-dashboard-right-panel-inner">
              <Search />

              <div className="vcdn-dashboard-upload">
                <div className="vcdn-dashboard-upload-inner">
                  <div className="vcdn-dashboard-upload-main">
                    <div className="vcdn-dashboard-upload-title">Edit album</div>
                    <div className="vcdn-message-box" id="vcdn-message-box"></div>
                    <form action="" id="vcdn-albmum-panel-form" method="POST" onSubmit={submitHandler}>
                      <div className="vcdn-dashboard-form-group">
                        <label htmlFor="vcdn-albumname">Album Name</label>
                        <div className="vcdn-video-form-input">
                          <input type="text" name="vcdn-albumname" id="vcdn-albumname" placeholder="" defaultValue={albumTitle} />
                        </div>
                      </div>

                      <div className="vcdn-dashboard-form-group">
                        <div className="vcdn-video-form-button vcdn-video-form-button-left">
                          <button type="submit">Update Album</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAlbum;
